@media (max-width: 1199px) {
    .reason ul.rea_btn {
      width: 100%;
    }
  
    .rea_btn .col-sm-4 {
      padding-left: 2%;
    }
  }
  
  @media (min-width: 1080px) {
    .cd-primary-nav > li > a {
      padding: 0 10px;
    }
  
    .has-children > a {
      padding-right: 40px;
    }
  
    .cd-primary-nav {
      width: 55% !important;
    }
  }
  
  @media (min-width: 960px) and (max-width: 1079px) {
    .cd-primary-nav {
      > li > a {
        padding: 0 5px;
      }
  
      width: 58% !important;
    }
  }
  
  @media (max-width: 960px) {
    .cd-primary-nav > li > a {
      padding-left: 15px !important;
    }
  
    .message {
      .flex {
        display: block;
      }
  
      .img-wrap {
        width: 100%;
        margin-bottom: 1em;
      }
  
      .inner_right {
        flex: none;
        padding: 0;
      }
    }
  }
  
  @media (max-width: 768px) {
    .pc--only {
      display: none;
    }
  
    h2, .h2 {
      font-size: 28px;
      line-height: 1.6;
      margin: 0;
    }
  
    h3, .h3 {
      font-size: 2.4rem;
    }
  
    h4, .h4 {
      font-size: 18px;
    }
  
    .w90-bnr {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  
    .sp_table tbody tr {
      th {
        font-size: 1.15em;
        display: block;
        width: 100% !important;
        border-bottom: none;
      }
  
      td {
        font-size: 1.05em;
        display: block;
        width: 100% !important;
        border-top: none;
      }
    }
  
    /*----- index layout -----*/
  
    .l-top-contener .inner__block, #containar img {
      width: 100%;
    }
  
    .top--topics {
      padding: 0 0 40px;
      background-color: #efefef;
    }
  
    /* tabbox */
  
    #tabMenu {
      ul {
        width: 100%;
      }
  
      li {
        float: none;
        width: 100%;
        display: none;
  
        &:first-child {
          display: block;
        }
  
        a.active {
          background-color: #24a6e3;
          color: #fff;
          font-size: 20px;
        }
      }
    }
  
    .top--topics dl {
      dt, dd {
        padding: 0 5px;
      }
  
      dt {
        display: block;
        width: 100%;
        margin-bottom: 5px;
  
        span {
          width: 100px;
        }
      }
    }
  
    /* tabbox END */
  
    .top--worry {
      background: #efefef url(../images/top/sec_bg.jpg) no-repeat center top;
      background-size: cover;
      padding: 3em 0 0;
  
      h2 {
        color: #fff;
        font-size: 30px;
      }
  
      .col-xs-6 {
        min-height: 340px;
        width: 48%;
        margin: 1%;
      }
  
      figure {
        max-width: 211px;
        width: 90%;
        margin: auto;
      }
  
      figcaption {
        background-color: #fff;
        padding: 30px 2% 0;
        margin: auto;
        width: 94%;
        min-height: 105px;
        top: 185px;
        left: 0;
        right: 0;
        z-index: 2;
        box-shadow: -5px 5px 0px rgba(0, 0, 0, 0.2);
  
        .pos_ttl {
          width: 100%;
          font-size: 12px;
        }
  
        .marker {
          font-size: 15px;
        }
      }
    }
  
    /*.top--worry a:hover img{opacity: 1;}*/
  
    .top--case {
      padding: 40px 0;
    }
  
    .link_box {
      position: relative;
  
      a:hover {
        background-color: #fff;
        filter: alpha(opacity = 30);
        -moz-opacity: 0.3;
        opacity: 0.3;
      }
    }
  
    .top--case {
      div.row {
        display: block;
      }
  
      .col-sm-3 {
        margin: 2% auto;
        width: 90%;
        float: none;
      }
  
      .r_btn {
        &.mt3 {
          margin-top: 2em;
        }
  
        a {
          width: 80%;
          font-size: 16px;
        }
      }
    }
  
    .top--advantage {
      margin: 2em 0;
      padding: 2em 0;
      background-color: #efefef;
  
      .row {
        display: block;
      }
  
      .col-sm-4 {
        width: 86%;
        margin: 0 auto;
        float: none;
        background-color: #fff;
        border: none;
        box-shadow: 4px 4px 0 0 #ddd;
  
        &.center-col {
          margin: 4% auto;
        }
  
        h3 {
          font-weight: 600;
          margin-top: 15px;
          font-size: 24px;
  
          &.sm {
            font-size: 20px;
            margin-top: 10px;
          }
        }
      }
  
      .mt3 {
        margin-top: 2em;
      }
    }
  
    .top--contact {
      padding: 0 0 4em;
  
      .row {
        display: block;
      }
  
      .col-sm-4 {
        width: 86%;
        padding: 0 0 2%;
        margin: auto;
        float: none;
        text-align: center;
  
        &.center-col {
          margin: 4% auto;
        }
  
        h3 {
          margin: 0;
          line-height: 1;
  
          span {
            display: block;
            padding: 8px 0;
            text-align: center;
            background-color: #24a6e3;
            color: #fff;
            font-size: 16px;
          }
        }
  
        p {
          font-weight: 600;
          margin: 10px 0;
  
          &.tel-txt {
            font-size: 30px;
  
            em {
              font-style: normal;
              font-size: 0.8em;
            }
          }
        }
      }
    }
  
    .top--flow {
      padding: 3em 0;
      background-color: #efefef;
  
      figure {
        width: 96%;
        margin: auto;
      }
    }
  
    .message {
      padding: 3em 0;
  
      .inner__block {
        width: 92%;
      }
  
      h2.h2__ttl--center .bdr {
        font-size: 24px;
        line-height: 2;
      }
  
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.6;
      }
  
      .img-wrap {
        width: 50%;
      }
    }
  
    .foot--bnr {
      max-width: 1180px;
      margin: auto;
  
      .col-sm-6 {
        padding: 7px;
      }
    }
  
    .foot-area {
      .img-wrap {
        max-width: 490px;
        margin: auto;
      }
  
      .inner_right {
        flex: none;
        margin: auto;
        font-size: 19px;
      }
    }
  
    /*----- sub layout -----*/
  
    .neg__block {
      max-width: 960px;
      margin: -120px auto 0;
      padding: 65px 0 0;
      background-color: #fff;
  
      .inner__block {
        max-width: 810px;
      }
    }
  
    /* ttl */
  
    .h2__ttl--center {
      font-size: 24px;
      margin-bottom: 30px;
    }
  
    .h2__ttl--left {
      font-weight: 600;
      line-height: 1.6;
      margin-bottom: 35px;
      padding-bottom: 20px;
    }
  
    .h3__ttl--left {
      font-size: 20px;
      margin-bottom: 15px;
    }
  
    .h3_icon01 {
      background: url(../images/common/icon01.png) no-repeat center top;
      padding: 70px 0 10px;
    }
  
    .h3_icon02 {
      background: url(../images/common/icon02.png) no-repeat center top;
      padding: 70px 0 10px;
    }
  
    .h3_icon03 {
      background: url(../images/common/icon03.png) no-repeat center top;
      padding: 70px 0 10px;
    }
  
    .h3_icon04 {
      background: url(../images/common/icon04.png) no-repeat center top;
      padding: 70px 0 10px;
    }
  
    .h3_no {
      font-size: 18px;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      padding: 4px 10px;
      margin-right: 10px;
      text-shadow: 1px 0px 0px #1e87b9;
    }
  
    .fosm {
      font-size: 0.75em;
      display: block;
      padding: 5px 0;
    }
  
    .bar__ttl {
      font-size: 1.6rem;
      background-color: #808080;
      color: #fff;
      padding: 10px 0;
      text-align: center;
    }
  
    .sm--ttl {
      font-weight: 600;
      margin-bottom: 25px;
      padding-bottom: 5px;
    }
  
    /* font */
  
    .txt-yellow {
      color: #f8dc1f;
    }
  
    .txt-blue {
      color: #1d97d0;
    }
  
    .txt-orenge {
      color: #f17d00;
    }
  
    .txt-red {
      color: #f00;
    }
  
    .marker {
      background: linear-gradient(transparent 60%, #edf268 60%);
    }
  
    /*　btn　*/
  
    .arw--left {
      background: url(../images/common/arw_01.png) no-repeat left center;
      padding-left: 35px;
      display: inline-block;
    }
  
    .arw--right {
      background: url(../images/common/arw_01.png) no-repeat right center;
      padding-right: 35px;
      display: inline-block;
    }
  
    .submit_btn {
      -webkit-appearance: none;
      background: url(../images/contact/button_bg.png) no-repeat left top;
      background-size: cover;
      border: none;
      line-height: 1;
      padding: 18px 110px;
      font-size: 18px;
      text-align: center;
      font-weight: 600;
      color: #fff;
    }
  
    .r_btn {
      a {
        display: block;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 0;
        text-align: center;
        border: 1px solid;
        border-radius: 30px;
        font-size: 20px;
        box-sizing: border-box;
        -webkit-transition: all .3s;
        transition: all .3s;
      }
  
      &.txt-blue a {
        border-color: #1d97d0;
        color: #1d97d0;
  
        &:hover {
          border-color: #10719f;
          color: #10719f;
        }
      }
    }
  
    a {
      &.button {
        display: block;
        width: 80%;
        font-size: 16px;
  
        &:after {
          content: "";
          background: url(../images/common/arw.png) no-repeat 80% center;
          position: absolute;
          top: 38%;
          right: 10%;
          width: 12px;
          height: 16px;
          background-size: 12px 16px;
          -webkit-transition: all .3s;
          transition: all .3s;
        }
  
        &:hover {
          background-color: #1d97d0;
          color: #fff;
  
          &:after {
            right: 7%;
            filter: alpha(opacity = 0);
            opacity: 0;
          }
        }
      }
  
      &.button02 {
        display: block;
        max-width: 240px;
        margin-left: auto;
        margin-right: auto;
        border: 3px solid #1d97d0;
        background-color: #1d97d0;
        padding: 15px 0 12px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        position: relative;
        box-sizing: border-box;
  
        &:after {
          content: "";
          background: url(../images/common/arw02.png) no-repeat 80% center;
          position: absolute;
          top: 35%;
          right: 10%;
          width: 16px;
          height: 16px;
          background-size: 16px 16px;
          -webkit-transition: all .3s;
          transition: all .3s;
        }
  
        &:hover:after {
          right: 7%;
          filter: alpha(opacity = 0);
          opacity: 0;
        }
      }
    }
  
    /* table */
  
    .def__tbl {
      width: 100%;
  
      th, td {
        padding: 25px 0;
        border-bottom: 1px solid #afafaf;
      }
  
      th {
        width: 25%;
        vertical-align: top;
        font-size: 18px;
      }
  
      td {
        font-size: 14px;
      }
    }
  
    .def__tbl02 {
      width: 100%;
      border-left: 1px solid #afafaf;
  
      th, td {
        padding: 15px 20px;
      }
  
      th {
        vertical-align: top;
        font-weight: 500;
        background-color: #9c9c9c;
        color: #fff;
        border-right: 1px solid #fff;
      }
  
      td {
        font-size: 14px;
        border-right: 1px solid #afafaf;
        border-bottom: 1px solid #afafaf;
      }
    }
  
    /* list */
  
    .disc--list li {
      list-style: disc outside;
      margin: 0 0 10px 20px;
      font-size: 0.85em;
    }
  
    #l-sub-contener {
      margin-bottom: 4em;
      padding-top: 2.5em;
  
      p {
        font-size: 1.4rem;
      }
    }
  
    .mb_13 {
      margin-bottom: 40px;
    }
  
    .mt_13 {
      margin-top: 40px;
    }
  
    .tx_cen {
      text-align: center;
    }
  
    .p15 {
      padding: 150px 0;
    }
  
    .h4_ttl {
      border-left: 5px solid #ee9223;
      font-size: 1.6rem;
      margin-top: 20px;
  
      span {
        font-size: 0.85em;
      }
    }
  
    /* reason
    -------------------------*/
  
    .bg_gr {
      background: #eee;
      padding: 5px 0 1em;
    }
  
    .reason ul.rea_btn {
      width: 100%;
      margin: 0 auto;
    }
  
    .rea_btn {
      .col-sm-4 {
        width: 90%;
        padding-left: 0;
        margin: 0 auto 10px;
      }
  
      li {
        margin-right: 10px;
  
        &:first-child a {
          color: #fff;
          background: url(../images/common/arw04.png) no-repeat 95%, #24a6e3;
          border: 2px solid #24a6e3;
        }
      }
    }
  
    .reason .h3__ttl--left {
      font-size: 18px;
    }
  
    .rea_btn {
      a {
        padding: 10px 4%;
        font-size: 1.5rem;
        background-position: 95% center;
      }
  
      li a {
        font-size: 15px;
      }
    }
  
    /* merit
    -------------------------*/
  
    .disability .rea_btn li:first-child a, .merit .rea_btn li:first-child a {
      background-position: 95% center;
    }
  
    .merit_box {
      color: #24a6e3;
      border: 6px double #24a6e3;
      padding: 5% 4%;
  
      p {
        font-size: 1.6rem !important;
        text-align: left;
      }
    }
  
    .merit .d_table {
      background: #eee;
      padding: 5%;
      font-size: 1.6rem;
  
      table {
        width: 100%;
      }
  
      thead th {
        background: #959595;
        color: #fff;
        text-align: center;
        border: 2px solid #eee;
        padding: 15px 0;
      }
  
      tbody {
        th, td {
          background: #fff;
          text-align: center;
          border: 2px solid #eee;
          padding: 15px 0;
        }
      }
    }
  
    /* disability
    -------------------------*/
  
    .disability {
      h3 {
        font-size: 2rem;
      }
  
      .d_table {
        background: none;
        padding: 0;
        font-size: 1.8rem;
        margin: 20px 0;
      }
  
      .ta_ttl {
        background: #1386d7;
        text-align: center;
        border: 2px solid #eee;
        border-bottom: none;
        padding: 15px 0;
        color: #fff;
        font-size: 1.9rem;
      }
  
      .d_table {
        table {
          width: 100%;
          font-size: 1.2rem;
        }
  
        thead {
          font-size: 1.1rem;
          font-weight: 400;
  
          th {
            padding: 2%;
            width: 18%;
            text-align: center;
  
            &:first-child {
              width: 5%;
            }
  
            &:nth-child(2) {
              width: 22%;
            }
          }
        }
  
        tbody {
          th, td {
            text-align: center;
            padding: 2%;
          }
        }
  
        &.type-table, &.type-table02 {
          thead th {
            &:first-child {
              width: 50%;
            }
  
            &:nth-child(2) {
              width: 15%;
            }
  
            &:nth-child(3) {
              width: 35%;
            }
          }
  
          tbody th {
            text-align: left;
          }
        }
      }
  
      .d_table02 {
        background: none;
        padding: 0;
        font-size: 1.3rem;
        margin: 20px 0;
  
        .ta_ttl {
          background: #5e5e5e;
          text-align: center;
          border: 2px solid #eee;
          border-bottom: none;
          padding: 10px 0;
          color: #fff;
          font-size: 1.9rem;
        }
  
        table {
          width: 100%;
        }
  
        thead th {
          padding: 2%;
          text-align: center;
  
          &:first-child {
            width: 30%;
          }
        }
  
        tbody {
          th, td {
            padding: 2%;
          }
  
          th {
            text-align: center;
          }
        }
      }
    }
  
    .insp {
      background: #eee;
      padding: 20px;
  
      dl {
        background: #fff;
        padding: 15px 20px 20px;
        box-shadow: 5px 5px 0px -2px #d6d6d6;
        -moz-box-shadow: 5px 5px 0px -2px #d6d6d6;
        -webkit-box-shadow: 5px 5px 0px -2px #d6d6d6;
      }
  
      dt {
        background: url(../images/common/check.png) no-repeat left center;
        color: #ee9223;
        font-size: 1.8rem;
        padding: 5px 0 5px 24px;
        border-bottom: 1px dashed #959595;
        margin-bottom: 10px;
      }
    }
  
    .r_list {
      padding: 4% 4% 2%;
  
      li {
        font-size: 1.5rem;
        padding: 10px 0px 10px 35px;
        margin-bottom: 10px;
      }
    }
  
    .r_list02 {
      background: #eeeeee;
      padding: 4% 4% 2%;
  
      li {
        background: #fff;
        font-size: 1.6rem;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 5px 5px 0px -2px #d6d6d6;
        -moz-box-shadow: 5px 5px 0px -2px #d6d6d6;
        -webkit-box-shadow: 5px 5px 0px -2px #d6d6d6;
  
        &:first-letter {
          color: #fff;
          font-weight: bold;
          font-size: 1.8rem;
          background: #f17d00;
          border-radius: 18px;
          -webkit-border-radius: 18px;
          -moz-border-radius: 18px;
          padding: 2px 8px;
          margin-right: 10px;
  
          /*text-shadow:1px 0px 0px #1e87b9;*/
        }
      }
    }
  
    /* money
    -------------------------*/
  
    .money {
      .ta_ttl {
        background: #1386d7;
        text-align: center;
        border: 2px solid #eee;
        border-bottom: none;
        padding: 15px 0;
        color: #fff;
        font-size: 1.9rem;
      }
  
      .d_table {
        background: none;
        padding: 0;
        font-size: 1.3rem;
  
        table {
          width: 100%;
        }
  
        th {
          padding: 2%;
          width: 35%;
        }
  
        tbody td {
          padding: 2%;
        }
      }
    }
  
    /* flow
    -------------------------*/
  
    .flow_box {
      background: #fff;
      padding: 0 5%;
      border: 6px double #eee;
      margin: 2em 0;
    }
  
    .flow .box {
      background: -moz-linear-gradient(top, #FFF 0%, #EEE);
      background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#EEE));
      border: 1px solid #DDD;
      width: 96%;
      text-align: center;
      padding: 4% 0 2%;
      margin-bottom: 10px;
      line-height: 1.3;
  
      p {
        margin: 0;
      }
    }
  
    #l-sub-contener.flow .box p.b--txt.fsize18 {
      font-weight: 500;
      line-height: 1.2;
      font-size: 16px;
      color: #444;
    }
  
    .flow {
      .box div {
        &.tel {
          font-size: 2.4rem;
        }
  
        em {
          font-size: 0.8em;
          font-style: normal;
        }
      }
  
      a.button {
        font-size: 14px;
        margin: 1em auto;
  
        &:link {
          font-size: 14px;
          margin: 1em auto;
        }
      }
    }
  
    /* case
    -------------------------*/
  
    .case_detail h2 {
      font-size: 2rem;
      border: none;
      border-bottom: 2px solid #1386d7;
      padding: 0 0 10px;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      margin: 0 0 30px;
      text-align: left;
    }
  
    .case .d_table {
      background: #eee;
      padding: 10px;
      font-size: 1.6rem;
  
      table {
        width: 100%;
      }
  
      thead th {
        padding: 4%;
      }
  
      tbody {
        th, td {
          padding: 4%;
          font-size: 14px;
        }
      }
  
      th:first-child {
        display: none;
      }
  
      a {
        color: #1386d7;
  
        &:hover {
          color: #2ea1f1;
        }
      }
  
      tbody tr td:nth-child(2) {
        width: 700px;
      }
    }
  
    /* faq
    -------------------------*/
  
    .faq {
      p.text-center {
        text-align: left;
      }
  
      dt {
        font-size: 1.6rem;
  
        &:before {
          content: "Q.";
          margin-right: 7px;
          font-size: 1.15em;
        }
      }
  
      dd {
        font-size: 1.4rem;
        padding: 12px 10px 12px 30px;
        margin-bottom: 30px;
        position: relative;
  
        &:before {
          content: "A.";
          position: absolute;
          left: 8px;
          top: 12px;
          font-weight: 600;
          font-size: 1.8rem;
          color: #ddd;
        }
      }
    }
  
    /* cost
    -------------------------*/
  
    .cost {
      table {
        width: 100%;
        font-size: 1.2rem;
  
        span.small {
          display: block;
        }
      }
  
      thead th {
        padding: 2%;
        font-weight: 400;
  
        &:nth-child(2), &:nth-child(3) {
          width: 25%;
        }
      }
  
      tbody {
        th, td {
          padding: 2%;
        }
  
        th {
          font-weight: 400;
          width: 25%;
        }
      }
  
      table.w50 {
        th {
          width: 60%;
        }
  
        td {
          width: 40%;
          text-align: center;
        }
      }
    }
  
    /* voice */
    /* voice */
  
    .voice {
      p.text-center {
        width: 96%;
        margin: 0 auto 1em;
      }
  
      .voice--list {
        overflow: hidden;
  
        li {
          float: left;
          width: 31.333%;
          margin: 2% 1% 0;
          border: 1px solid #ddd;
  
          &:nth-child(3n+2) {
            margin: 2% 1% 0;
          }
  
          figure {
            position: relative;
            width: 100%;
            max-height: 340px;
            overflow: hidden;
  
            img {
              width: 100%;
            }
          }
        }
      }
    }
  
    /* topics
    -------------------------*/
  
    .topic dl {
      display: table;
      width: 100%;
      border-bottom: 1px dashed #ddd;
      padding-bottom: 20px;
      margin-bottom: 25px;
  
      dt, dd {
        padding: 0 5px;
      }
  
      dt {
        display: block;
        width: 100%;
        margin-bottom: 10px;
  
        span {
          width: 100px;
        }
      }
    }
  
    .rea_btn a {
      text-decoration: none;
      display: block;
      width: 100%;
      padding: 15px 35px;
      font-size: 1.8rem;
      border: 2px solid #24a6e3;
      transition: 0.5s;
    }
  
    .topic .rea_btn {
      margin-bottom: 3em;
  
      .col-sm-3 {
        padding: 1% 1%;
      }
  
      a {
        font-size: 1.4rem;
        padding: 4% 0;
      }
    }
  
    .rea_btn a {
      &.news {
        color: #24a6e3;
        border-color: #24a6e3;
  
        &:hover {
          background-color: #24a6e3;
        }
      }
  
      &.consultation {
        color: #ee9223;
        border-color: #ee9223;
  
        &:hover {
          background-color: #ee9223;
        }
      }
  
      &.important {
        color: #fe1800;
        border-color: #fe1800;
  
        &:hover {
          background-color: #fe1800;
        }
      }
  
      &.seminar {
        color: #4a7b06;
        border-color: #4a7b06;
  
        &:hover {
          background-color: #4a7b06;
        }
      }
  
      &:hover {
        color: #fff;
      }
    }
  
    /* topic_detail */
  
    .topic_detail {
      .t_item {
        background: #888888;
        color: #fff;
        padding: 2px 20px;
        margin-right: 10px;
      }
  
      h2 {
        font-size: 2rem;
        padding: 20px 0 10px;
      }
    }
  
    .editor-block {
      margin-bottom: 5em;
  
      h2 {
        font-size: 2.4rem;
      }
  
      h3 {
        font-size: 1.8rem;
      }
  
      h4 {
        font-size: 1.6rem;
      }
  
      p {
        font-weight: 400;
      }
    }
  
    /* contact */
  
    .contact {
      .h2__ttl--center {
        line-height: 1.4;
      }
  
      label {
        font-size: 1.1em;
        margin-bottom: 8px;
      }
  
      .btn {
        padding: 8px 40px;
        margin: 10px;
      }
  
      .req {
        margin-left: 10px;
        font-size: 0.8em;
        font-weight: 400;
        color: firebrick;
      }
  
      table.table {
        th, td {
          padding: 2% 4%;
        }
  
        th {
          width: 25%;
        }
      }
    }
  
    /* sitemap */
  
    .catetag {
      font-size: 1.15em;
    }
  
    ul {
      &.sitemap--list li {
        width: 100%;
  
        &:nth-child(3n+3) {
          margin-right: 1%;
        }
  
        &:nth-child(even) {
          margin-right: 0;
        }
  
        a {
          padding: 4% 4% 4% 9%;
          font-size: 1.4rem;
        }
      }
  
      /* privacy */
      &.privacy--list {
        padding: 0 5%;
  
        li {
          padding: 6% 0;
          font-size: 1em;
        }
      }
    }

    /* line_yoyaku */
    .ol-list-01{
      li{
        padding-left:30px;
        font-size: 1.4rem;
      }
    }

    .ul-list-01{
      li{
        font-size: 1.4rem;
      }
    }

    .line__wrap{
      &-flow{
        padding: 20px;
        &--ttl{
          margin: 0 0 20px;
          font-size: 18px;
        }
        &--link{
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -ms-flex-wrap: wrap;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          li{
            img{
              width: auto!important;
              zoom: .5;
            }
          }
        }
        &--attention{
          padding: 30px 15px 15px;
          &---ttl{
            font-size: 14px;
            left: 15px;
          }
        }
        &--cost{
          padding: 30px 15px 15px;
          &---ttl{
            font-size: 14px;
            left: 15px;
          }
          &---contents{
            .items{
              display: block;
              margin: 0;
              dt{
                width: auto;
                margin-bottom: 10px;
                span{
                  font-size: 14px;
                }
              }
              dd{
                width: auto;
                .title{
                  font-size: 14px;
                }
                .list{
                  margin-left: 10px;
                  li{
                    &:not(:last-child){
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

  }
  
  @media (max-width: 480px) {
    /*----- index layout -----*/
  
    .top--worry {
      .col-xs-6 {
        min-height: 260px;
      }
  
      figcaption {
        top: 140px;
      }
    }
  
    .top--case .col-sm-3 {
      margin: 5%;
    }
  }